<template>
  <div class="city">
    <Header />
    <div class="content">
      <div class="city-title">智慧城管解决方案</div>
      <p class="title-content">
        智慧城管是基于网格化管理，以地理信息系统为核心的，新一代信息技术支撑、社会知识创新环境下的城市管理新模式。
      </p>
      <p class="title-content">
        通过，全面感知、泛在互联、应用融合等方式提高城市管理水平及效率，从而改善城市整体形象，提升城市管理科学化、精细化、智能化水
      </p>
      <div style="text-align: center" class="img-box">
        <img
          class="content_img"
          src="https://aierbanjia-official-web.oss-cn-chengdu.aliyuncs.com/web/detail_cg.png"
          alt="智慧园区解决方案"
        />
      </div>
      <h4 style="font-weight:400;">智慧城管相关技术</h4>
      <div class="service_wrap">
        <div
          v-for="(item, index) in service"
          :key="item.title"
          :style="{
            borderLeftColor: index % 4 === 0 && 'transparent',
            borderTopColor: index < 4 && 'transparent',
          }"
          class="service_item"
        >
          <img :src="item.imgUrl" :alt="item.title" />
          <p class="service-title">{{ item.title }}</p>
        </div>
      </div>
      <div class="footer" @click="jumpTo">下一篇：智慧园区解决方案 >></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  name: 'City',
  components: { Header },
  data() {
    return {
      service: [
        {
          title: '物联网',
          imgUrl: require('../../static/images/icon-wlw.png'),
        },
        {
          title: '云计算',
          imgUrl: require('../../static/images/icon-yjs.png'),
        },
        {
          title: '移动网络',
          imgUrl: require('../../static/images/icon-ydwl.png'),
        },
        {
          title: '互联网',
          imgUrl: require('../../static/images/icon-hlw.png'),
        },
        {
          title: '传感',
          imgUrl: require('../../static/images/icon-cg.png'),
        },
        {
          title: '数字图像处理',
          imgUrl: require('../../static/images/icon-sztxcl.png'),
        },
        {
          title: 'GIS',
          imgUrl: require('../../static/images/icon-gis.png'),
        },
        {
          title: '其他',
          imgUrl: require('../../static/images/icon-qt.png'),
        },
      ],
    }
  },
  methods: {
    jumpTo() {
      this.$router.push('/details/park')
    }
  }  
}
</script>

<style scoped>
h4{
  font-size: 0.4rem;
  color: rgba(39, 32, 27, 1);
  margin-top: 1.3rem;
  margin-bottom: 1.5rem;
}
.title-content {
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
  font-size: 0.37rem;
  margin-bottom: 0.91rem;
  text-indent: 2em;
}
.city-title{
  font-size: 0.37rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.7rem;
}
.content_img{
  width: 9.24rem;
  height:6.19rem;
}
.city-technology{
  margin-top: 1.31rem;
  margin-bottom: 1.69rem;
  width: 3.59rem;
  height: 0.37rem;
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201B;
}
.service_wrap {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.service_item {
  width: 2.28rem;
  margin-bottom: 1.31rem;
}
.service_item img {
  width: 1.64rem;
}
.service-title{
  text-align: center;
  width: 2.01rem;
  height: 0.32rem;
  font-size: 0.32rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.footer {
  cursor: pointer;
  margin-right: 0.2rem;
  text-align: right;
  width: 100%;
  height: 0.31rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 0.2rem;
  margin-bottom: 0.6rem;
}
</style>